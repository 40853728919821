<template lang="pug">
  div
    PageHeader(text="terms & conditions" font="white" back="black")
    b-container(class="content")
      p The following terms and conditions (‘The Terms’) shall be incorporated into every contract for sale and/or delivery of goods (‘the goods’) by {{ addressText }} (‘The Seller’). All quotations given, orders accepted and credit extended by The Seller is subject to the following terms.
      ol
        li
          p(class="header") Retention of Title
          p The Buyer hereby acknowledges that the goods supplied by the Seller shall remain the property of the Seller until the Seller receives full payment from The Buyer.
        li
          p(class="header") Pricing
          p Prices quoted by way of a representative of The Seller or Published price lists are subject to change without notice and are not binding by The Seller. The prices of goods shall be exclusive of all delivery charges and insurance costs which shall be payable by the buyer. The Seller reserves the right to increase prices to take into account any increase in cost to The Seller or for supplying of Goods. All clerical errors in prices are subject to correction by The Seller.
        li
          p(class="header") Acceptance Of Order
          p (a) The Seller’s acceptance of the Order is subject to these terms and conditions of sale and to any additional terms and conditions that may be contained in the Seller’s price lists or quotations pursuant to which the order was given.
          p (b) These terms and conditions of sale may only be amended with the written consent of the Seller.
          p (c) The Terms and conditions of sales referred to in clause 3a shall take precedence over any additional or inconsistent terms and conditions contained in the Buyers order, or in the Buyers request for quotation.
        li
          p(class="header") Acceptance of Goods
          p Acceptance of goods delivered by the Buyer shall be deemed to have taken place at the expiration of 3 days from the date of delivery to the buyer.
        li
          p(class="header") Delivery
          p The Seller will make all reasonable efforts to have the goods delivered to the Buyer on the date agreed between the parties as the delivery date, but the Supplier shall be under no liability whatsoever should delivery not be made on this date. The seller reserves the right of supplying any order in full or part. All freight and transit charges are the responsibility of the Buyer.
        li
          p (class="header") Freight & Insurance
          p (a) Insurance is not included in the freight charge. Insurance, if required, is the responsibility of the purchaser and must be arranged before dispatching of goods.
          p (b) Customers wishing to nominate another freight company and or freight method, other than the one used by the Seller, must make arrangements prior to dispatch of goods.
          p (c) All cost incurred and insurance, if required, are the responsibility of the purchaser.
        li
          p(class="header") Payment
          p Standard terms are prepaid. Payment must be made in full at time of purchase. Direct payments can be paid by PayPal, credit card (powered by PayPal), Przelewy24 (powered by PayPal), etc. The updated list of these parties may be requested from the Owner at any time.
        li
          p(class="header") Payment Procedures
          p (a) Existing customers with established history and agreed payment terms
          ol
            li:p Ozparts will aim to send an automated email with a reminder about the due amount on the day that payment is due. Having the email sent to a customer will mean that to that date the payment was not received.
            li:p 7 days past the invoice date Ozparts will send another email with a reminder and full details of the invoice.
            li:p 15 days past the invoice due date Ozparts will send one more email with a reminder. At that time the account of the customer will be put on hold until the payment has been made.
            li:p If the overdue amount is not paid within 30 days from the due date, Ozparts will pass the matter to the legal office tasked with procedure of recovery of the debt.
          p Customers that are regularly or extensively delaying their due payments will most likely lose the possibility of having extended payment terms with Ozparts.
          p (b) New customers
          ol
            li:p Initial orders and transactions will need to be prepaid for the goods to be delivered.
            li:p After the initial period of trading (not less than 3 months) and sufficient history we will be happy to discuss providing some credit and terms (value of the credit and payment terms to be discussed directly).
        li
          p(class="header") Use of Trade Name and Trademarks
          p OZPARTS is a trademark of The Seller. Customers shall not use OZPARTS, {{addressText}}, OZPARTS UK LIMITED trade name or trademarks, or any portion thereof, within the customer’s name. Customers shall have no right, interest in, or claim, on any such trade names or trademarks.

        li
          p(class="header") Catalogues, Price Lists and Technical References
          p Catalogues, Price Lists and Technical References provided to the Purchaser by the Supplier whether in printed or electronic form shall remain the property of the Supplier at all times and are not to be reproduced by any means without written approval of the Supplier. The Supplier shall not be held responsible for any errors or omissions in said Catalogues, Price Lists and Technical References.
        li
          p(class="header") LUCID Packaging Register number ( EPR )
          p We are registered with the Zentrale Stelle Verpackungsregister (Central Agency Packaging Register - 'ZSVR') and our LUCID Packaging Register number ( EPR ) is as follows: DE3891082047406.
</template>

<script>
import { mapState } from "vuex";
import PageHeader from "@/components/common/PageHeader";

export default {
  name: "TermsEn",

  components: { PageHeader },

  computed: {
    ...mapState(["userRegionData"]),

    currentRegion() {
      return this.userRegionData.location.name;
    },

    addressText() {
      return this.currentRegion !== "NL"
        ? "OZPARTS PL sp. z o.o."
        : "OZPARTS B.V.";
    }
  }
};
</script>
