<template>
  <div>
    <b-table
      v-if="applications.length"
      striped
      responsive
      stacked="md"
      class="applications-table"
      :items="formattedApplications"
      :fields="tableFields"
      hover
      selectable
      select-mode="single"
      @row-selected="goToCatalogue"
    >
      <template #cell(parameter)="row">
        <b-container class="p-2">
          <b-row class="mb-1">{{ row.value.param1 }}</b-row>
          <b-row class="mb-1">{{ row.value.param2 }}</b-row>
          <b-row>{{ row.value.param3 }}</b-row>
        </b-container>
      </template>
    </b-table>
    <div v-else-if="object.application" v-html="object.application"></div>
  </div>
</template>

<script>
import { useApplications } from "@/composables/useApplications";
export default {
  name: "Applications",
  props: {
    object: {
      type: Object,
      required: true
    },
    applications: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    formattedApplications() {
      return this.applications.map(item => ({
        ...item,
        peryear: item.peryear.map(year => parseInt(year)).join(", "),
        parameter: {
          param1: item.parameter,
          param2: item.parameter2,
          param3: item.parameter3
        }
      }));
    }
  },
  setup: (props, ctx) => useApplications(props.object.manufacturergroup, ctx)
};
</script>

<style lang="scss" scoped>
.applications-table {
  max-width: 100%;
  margin: 20px 0;
}

@media (max-width: 576px) {
  .applications-table {
    font-size: 0.9rem;
  }
}
</style>
